:root {
  --sw-toolbar-btn-background-color: #db2531;
  --sw-anchor-active-primary-color: #db2531;
  --sw-anchor-done-primary-color: #db2531;
}
body{
  color: #666677;
}

@media (min-width: 992px) {
  .offcanvas {
    visibility: visible;
    position: relative;
    background: none;
    border: none;
    justify-content: end;
    color: #db2531;
  }
  .navbar-expand-lg .offcanvas .offcanvas-body {
    justify-content: space-between;
    align-items: center;
  }

  .offcanvas_contact {
    display: none;
  }

  .video-container{
    padding-bottom: 56.25%;
  }
}



input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

@media (min-width: 992px) and (max-width: 1200px) {
  .navbar-expand-lg .offcanvas .nav-link {
    font-size: 12px;
  }
}

@media (max-width: 992px) {
  .offcanvas {
    width: 250px !important;
  }
  .offcanvas-start-lg {
    top: 0;
    right: 0;
    border-right: 1px solid rgba(0, 0, 0, .2);
    transform: translateX(100%);
  }
  .tracking_form {
    display: none;
  }
  .navbar-brand {
    img {
      max-width: 100px;
    }
  }
}
.pre-wrap {
  white-space: pre-wrap
}

.navbar-toggler {
  line-height: 1 !important;
  width: 40px;
  height: 40px;
}

.offcanvas_contact li {
  i {
    margin-right: 10px;
  }

  font-size: 14px;
  color: #666677;
  display: flex;
  align-items: center;
  margin: 10px 0;
}

nav {
  box-shadow: 0px 1px 2px rgb(0 0 0 / 9%);
}

a {
  color: #666677;

  &:hover {
    color: #db2531;
  }
}

a:hover, a:visited, a:link, a:active {
  text-decoration: none;
}

.mt-6 {
  margin-top: 3.5rem;
}

section, footer {
  padding: 80px 0;
}

p {
  font-size: 15px;
  color: #666677;
  line-height: 25px;
  font-weight: 400;
}

h3 {
  font-weight: 700;
  font-size: 30px;
  color: #222222;
}

input, textarea {
  font-size: inherit;
  border: 1px solid #e6e7e8;
  border-radius: 3px;
  transition: border-color 0.3s;
  background-color: #fff;
  margin-top: 0;
  padding: 3px 10px;
  margin-bottom: 10px;

  &:focus {
    outline: none;
  }
}
textarea {
  background-color: #fff !important;
}

.tracking_form {
  input {
    padding: 10px 20px;
    margin-bottom: 0;
  }

  .search_button {
    position: relative;
    background: 0 0;
    color: #667;
    font-size: 17px;
    font-weight: 400;
    padding: 0 1px;
    margin-left: 10px;
    margin-bottom: -1px;
    min-width: 16px;
    border: none;
    box-shadow: none;
    transition: color .15s;
  }
}

.btn {
  letter-spacing: .02em;
  border-radius: 3px;
  line-height: 54px;
  background-color: #db2531;
  padding: 0px 20px;

  font-weight: 600;
  font-size: 16px;
  color: #fff;
  box-shadow: rgb(153 153 153) 0px 0px 0px 0px;

  &:hover, &:active, &:focus {
    background-color: #db2531 !important;
    color: #fff !important;
  }
}

.bg-gray {
  background-color: #f8f7f6;
}

.red_underline {
  &::after {
    content: '';
    display: block;
    height: 2px;
    width: 70px;
    background-color: #db2531;
    margin-top: 20px;
  }
}

.red_underline_1 {
  &::after {
    content: '';
    display: block;
    height: 2px;
    width: 70px;
    background-color: #db2531;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
  }
  &.start_line::after {
    margin-left: 0;
  }
}

.red-text {
  color: #db2531;
}

.clear_both {
  clear: both;
}

.w-780 {
  max-width: 780px;
  margin: 0 auto;
}


footer {
  h6 {
    margin-bottom: 20px;
  }

  ul li {
    margin: 10px 0;
  }

  a {
    font-size: 13px;
  }

  .social_ul {
    li {
      margin-right: 10px;

      a {
        font-size: 20px;
      }
    }
  }

  textarea, input {
    width: 100%;
  }
}

label.dropdown {
  position: relative;

  &::after {
    content: '>';
    font: 16px Consolas, monospace;
    color: #444;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
    right: 2px;
    top: 10px;
    border-bottom: 1px solid #aaa;
    position: absolute;
    pointer-events: none;
    width: 34px;
    padding: 0 0 5px;
    text-indent: 14px;
  }
}

label.dropdown select {
  padding: 10px 42px 10px 10px;
  background: #f8f8f8;
  color: #444;
  border: 1px solid #aaa;
  border-radius: 0;
  display: inline-block;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  outline: none;
}

.breadcrumb {
  background-color: #db2531;
  color: #fff;
  padding: 30px 0;
  margin: 20px 0 20px;
  border-bottom: 1px solid #eee;

  h2 {
    margin-bottom: 0;
  }

  a {
    color: #fff;
  }

  @media (max-width: 992px) {
    font-size: 14px;
    padding: 10px 0;
    margin: 20px 0 0;
  }
}

.sw .toolbar > .sw-btn {
  background-color: #2ecc71 !important;
  border-color: #0ba94e !important;
  color: #ffffff !important;
  padding: 12px 16px !important;
  line-height: 1;

  &.sw-btn-next {
    margin-left: auto;
  }

  &.sw-btn-prev {
    background-color: #b8020e !important;
    border-color: #b8020e !important;
  }
}

.sw > .tab-content {
  min-height: auto !important;
  height: auto !important;
  overflow: visible;
}

.sw-theme-dots > .nav .nav-link.done::after {
  background-color: #91cc91;
}

.sw-theme-dots > .nav .nav-link.done {
  color: #91cc91;
}

table {
  border-spacing: 0;
  border-collapse: collapse;
  background-color: transparent;
  margin: 0 0 1.75em;
  table-layout: fixed;
  width: 100%;
}

table, th, td {
  border: 1px solid rgba(51, 51, 51, .1);
  font-size: 14px;
}

td, td img {
  vertical-align: top;
}

.shrink_video {
  max-width: 630px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.video-container {
  margin-top: 6rem;
  position: relative;
  padding-bottom: 6rem;
  margin-left: auto;
  margin-right: auto;
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.toolbar-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

pre {
  display: block;
  padding: 9.5px;
  margin: 0 0 10px;
  font-size: 13px;
  line-height: 1.42857143;
  color: #333;
  word-break: break-all;
  word-wrap: break-word;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.btn.disabled, .btn:disabled, fieldset:disabled .btn {
  pointer-events: none;
  background-color: #db2531;
  color: #fff;
}

#file{
  width: 390px;
}

#progress-wrp {
  border: 1px solid #e6e7e8;
  padding: 1px;
  position: relative;
  height: 38px;
  border-radius: 3px;
  margin-left: 10px;
  text-align: left;
  background: #fff;
  box-sizing: border-box;
  width: 100%;
}

#progress-wrp .progress-bar {
  height: 100%;
  border-radius: 3px;
  background-color: #2ecc71;
  width: 0;
  box-shadow: inset 1px 1px 10px rgba(0, 0, 0, 0.11);
}

#progress-wrp .status {
  top: 3px;
  left: 50%;
  position: absolute;
  display: inline-block;
  color: #000000;
  transform: translateX(-50%);
}
footer {
  padding-bottom: 20px;
}

@import "general";

.showcase {
  height: calc(100vh - 86px);
  min-height: 100% !important;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  color: #fff;
  padding: 20px 0;

  .showcase-title {
    font-size: 90px;
    line-height: 1;
    font-weight: 300;
    max-width: 400px;
    padding: 20px 0;
    margin-top: 30px;
  }

  .showcase-subtitle {
    color: rgba(249, 249, 249, 0.93);
    max-width: 400px;
    font-weight: 600;
    font-size: 18px;

  }

  .btn {
    z-index: 999999;
    position: relative;
    line-height: 64px;
    border-radius: 0;
    margin-top: 20px;
    padding: 0px 40px;
    @media (max-width: 767px) {
      padding: 0px 20px;
      line-height: 50px;
    }

    &:hover {
      background-color: #fff;
      color: rgb(51, 51, 51);
    }
  }
}

.trans-logo {
  display: flex;
  align-items: center;

  img {
    max-width: 44px;
    margin-right: 20px;
  }

  h4 {
    color: #666677;
    font-size: 14px;
    font-weight: 700;
  }
}

.center-content {
  p {
    margin: 60px 0;
  }

}

.left-logo {
  margin: 0 auto;
  max-width: 113px;
  height: auto;
}

.services {
  margin: 50px 0;

  h5 {
    font-weight: 400;
    font-size: 20px;
    color: #222222;
    margin: 0 20px 20px;
    min-height: 48px;
  }

  i {
    color: #db2531;
    margin-bottom: 15px;
    font-size: 40px;
    height: auto;
  }
}

.custom_card {
  border-radius: 2px;
  background: #ffffff;
  padding: 0;
  text-align: center;
  height: 580px;
  box-shadow: 0px 17px 35px rgb(74 74 115 / 10%), 0px 5px 15px rgb(0 0 0 / 7%);

  img {
    height: 180px;
    width: 100%;
    object-fit: cover;
  }

  a {
    color: #222;
    text-decoration: none !important;
    display: block;
  }

  h5 {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #666677 !important;

    @media screen and (min-width: 991px) {
      height: 100px;
    }

  }

  p, ul {
    padding: 0 40px;
    @media (max-width: 991px) {
      padding: 0 20px;

    }
  }

  @media screen and (min-width: 576px) {
    &.hover_effect {
      cursor: pointer;
      transition: transform .3s ease;

      &:hover {
        transform: translateY(-15px);
        transition: transform .3s ease;
      }
    }

  }

  ul li {
    margin: 20px 0;

    a {
      color: #db2531;
    }
  }
}

.about_us {
  h5 {
    font-size: 20px;
  }

  img {
    max-width: 200px;
    float: left;
    margin: 0 10px 10px 0;

    &:after {
      content: '';
      display: table;
      clear: both;
    }
  }
}

.custom-accordion {
  .accordion-button:not(.collapsed) {
    color: #000;
    background-color: transparent;
    box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
  }

  .accordion-button:focus {
    border-color: #000;
    box-shadow: none;
  }

  .accordion-button:not(.collapsed)::after {
    background-image: var(--bs-accordion-btn-icon);
  }

  h1 {

  }
}

.logos {
  img {
    max-height: 60px;
    width: auto;
    opacity: .7;
    transition: opacity .3s ease;
    backface-visibility: hidden;

    &:hover {
      opacity: 1;
    }
  }
}

.bottom_img {
  max-width: 80px;
}

.tab-content {
  textarea {
    width: 555px;

  }

  ul {
    padding-left: 10px;
  }

  label {
    font-weight: bold;
    font-size: 14px;
  }

  .image_upload {
    display: none;

    .btn {
      height: 38px;
      line-height: 1;
      margin-top: -3px;
      width: 105px;
    }
  }

  .contact_from {
    input {
      padding: 15px 20px;
      width: 100%;
      margin-bottom: 0;
    }

    @media (min-width: 992px) {
      width: 45%;
    }
  }
}

.newsletter {
  input {
    width: 50%;
    min-width: 150px;
    line-height: 52px;
    padding-top: 0;
    margin-bottom: 0;
  }
}

.help_faq_header {
  background-color: #0e1113;
  width: 100%;
  position: relative;
  overflow: hidden;
  padding: 120px 30px;

  h1 {
    font-size: 48px;
    font-weight: 500;
    text-align: center;
    color: #fff;

    @media (max-width: 992px) {
      font-size: 30px;
    }
  }

  input {
    margin-top: 20px;
    width: 100%;
    padding: 20px;
    border: none;
    background: rgba(255, 255, 255, .95);
    border-radius: 3px;
    box-shadow: 0 17px 35px rgb(74 74 115 / 10%), 0 5px 15px rgb(0 0 0 / 7%);
    @media (max-width: 992px) {
      padding: 10px 20px;
    }
  }

  .help_faq_form {
    max-width: 650px;
    margin: 0 auto;
  }
}

.resources {
  div {
    margin: 15px 0px;
    font-size: 14px;
    display: flex;
    align-items: center;
  }

  a {
    display: block;
  }

  i {
    margin-right: 20px;
    color: #db2531;
  }
}

.contact_wrapper {
  max-width: 930px;
  margin: 20px auto;

  input, textarea {
    width: 100%;
    box-sizing: border-box;
    margin-top: 3px;
    padding: 10px 15px;
    border: 1px solid #e6e7e8;
  }

}

.help-block {
  color: #A00000;
  font-size: 16px;
  margin-bottom: 13px;
}

.press_button {
  color: #337ab7;
  text-decoration: underline !important;

  &:hover {
    color: #337ab7;
  }
}

.pay_button {
  background-color: #2ecc71;
  border: none;
  color: #ffffff;
  text-decoration: none;
  padding: 0 40px;
  height: 50px;
  line-height: 50px;
  color: #fff;
  letter-spacing: 0.02em;
  font-weight: 500;
  font-size: 14px;
  display: inline-block;
  text-shadow: none;
  border-radius: 3px;
  cursor: pointer;

  &:hover {
    color: #ffffff;
  }
}

@media (max-width: 1200px) {
  .custom_card {
    height: 550px;
    margin-bottom: 30px;
  }

}

@media (min-width: 1199px) and (max-width: 1400px) {
  .custom_card p, .custom_card ul {
    padding: 0 20px;
  }
}

@media (min-width: 991px) and (max-width: 1199px) {
  .custom_card img {
    height: 230px;
  }
}


@media (max-width: 992px) {
  .gap_3 {
    gap: 50px;
  }
  section, footer {
    padding: 40px 0;
  }
  .trans-logo {
    margin-bottom: 40px;
    justify-content: center;
  }
  .center-content p {
    margin: 20px 0;
  }
  .custom_card {
    height: 550px;
    margin-bottom: 30px;
  }
  .showcase .showcase-title {
    font-size: 74px;
    margin-top: 50px;
  }
  #tracking_block {
    input#track_number {
      line-height: 40px !important;
    }

    .btn {
      height: 44px !important;
    }
  }

  .list-section .custom_card img {
    height: 230px;
    object-fit: fill;
  }
}

@media (max-width: 767px) {

  .custom_card img {
    height: 130px;
  }
  .custom_card h5 {
    padding: 20px;
  }
  .custom_card p, .custom_card ul {
    padding: 0 20px;
  }
  .tab-content textarea {
    width: 100% !important;
  }
  .twitter-typeahead {
    width: 100%;
  }
}

@media (max-width: 576px) {
  .gap_3 {
    gap: 30px;
  }
  .bottom_img {
    max-width: 50px;
  }
  .logos img {
    max-height: 40px;
  }

  .custom_card img {
    height: 260px;
    -o-object-fit: fill;
    object-fit: fill;
  }
  .custom_card {
    max-width: 100%;
    margin: 15px auto;
  }
  .custom_card h5 {
    height: auto;
  }
  .showcase .showcase-title {
    font-size: 54px;
  }
  #tracking_block {
    input#track_number {
      line-height: 30px !important;
    }

    .btn {
      min-width: 100px !important;
      height: 32px !important;
    }
  }


  .sw .toolbar-bottom {
    flex-direction: column-reverse;
    gap: 13px;
  }
  .sw-btn {
    width: 100% !important;
  }


  .breadcrumbs {
    margin-top: 10px;
  }

  #progress-wrp {
    display: none !important;
  }


}

.got_content {
  width: 100%;
  background-color: #bff;
  padding: 15px 15px 2px;
  margin-bottom: 40px;
}

.got_content table, .got_content td, .got_content tr {
  border: none;
}

.custom-pages {
  .shriner {
    max-width: 500px;
  }

  h3 {
    font-weight: 500;
    font-size: 20px;
  }

  p {
    margin-bottom: 1.75em;
    color: #666677;
  }

  table, tbody, tr, td {
    border: none;
  }
}

#tracking_block {
  .btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 141px;
    height: 54px;
  }
}

.loader {
  width: 30px;
  height: 30px;
  border: 5px solid #FFF;
  border-bottom-color: #FF3D00;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  margin-left: auto;
  margin-right: auto;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.not_payed_box {
  margin-top: 40px;

  li {
    color: #666677;
    margin-bottom: 10px;
  }
}

.telegram_info {
  > div {
    margin-top: 20px;
  }

  i {
    font-size: 26px;
    margin-right: 10px;
  }

  b, span {
    font-size: 24px;
  }
}

.events-table {
  margin-top: 25px;

  td {
    padding: 0.4em;
    font-weight: 500;
    text-align: left;
  }

  thead td {
    background-color: rgba(237, 125, 127, 0.33);
    font-weight: 500;
  }
}

#telegram_notfound {
  margin-top: 40px;
  color: #a94442;
  border-color: #ebccd1;
  background-color: #f2dede;
  border-radius: 4em;
  border: 1px solid transparent;
  overflow: hidden;
  padding: 1em 1em 1em 2em;
  box-sizing: border-box;

  p {
    font-weight: 500;
    color: #a94442;
  }
}


.inside_a {
  a {
    font-size: inherit;
  }
}

.sw {
  border-bottom: 1px solid #dddddd;
}

#search_text_input::-webkit-outer-spin-button,
#search_text_input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
#search_text_input {
  -moz-appearance: textfield;
}

#resend_link {
  color: #db2531;
}


.tt-menu,
.gist {
  text-align: left;
}

.typeahead,
.tt-query,
.tt-hint {
  width: 396px;
  height: 30px;
  padding: 8px 12px;
  font-size: 24px;
  line-height: 30px;
  border: 2px solid #ccc;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  outline: none;
}

.typeahead {
  background-color: #fff;
}

.typeahead:focus {
  border: 2px solid #0097cf;
}

.tt-query {
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.tt-hint {
  color: #999
}

.tt-menu {
  width: 100%;
  margin: 0;
  padding: 8px 0;
  background-color: #fff;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.2);
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, .2);
  -moz-box-shadow: 0 5px 10px rgba(0, 0, 0, .2);
  box-shadow: 0 5px 10px rgba(0, 0, 0, .2);
}

.tt-suggestion {
  padding: 3px 10px;
  font-size: 16px;
  line-height: 18px;
  margin-bottom: 7px;
  white-space: pre-wrap;
}

.tt-suggestion:hover {
  cursor: pointer;
  color: #fff;
  background-color: #db2531;
}

.tt-suggestion.tt-cursor {
  color: #fff;
  background-color: #0097cf;

}

.tt-suggestion p {
  margin: 0;
}

.gist {
  font-size: 14px;
}

.message_templates {
  list-style: none;
  padding-left: 0 !important;

  a {
    color: #337ab7;
    cursor: pointer;

    &:hover {
      color: #337ab7;
      text-decoration: underline;
    }
  }
}

.bottom_line {
  //box-shadow: 0 1px 2px rgba(0, 0, 0, 0.09);
  padding-bottom: 10px;
  margin-bottom: 20px;
}

.international_table {
  min-width: 516px;
}

.international_table th, .international_table td {
  padding: 0.5rem;
  font-weight: 400;
  text-align: left;
}

.international_table th {
  font-weight: 500;
}

.address_font {
  font-size: 1rem;
  margin-bottom: 9px;
  display: inline-block;
  font-weight: 500;
}


lite-youtube {
  background-color: #000;
  position: relative;
  display: block;
  contain: content;
  background-position: center center;
  background-size: cover;
  cursor: pointer;
  max-width: 720px;
}

/* gradient */
lite-youtube::before {
  content: attr(data-title);
  display: block;
  position: absolute;
  top: 0;
  /* Pixel-perfect port of YT's gradient PNG, using https://github.com/bluesmoon/pngtocss plus optimizations */
  background-image: linear-gradient(180deg, rgb(0 0 0 / 67%) 0%, rgb(0 0 0 / 54%) 14%, rgb(0 0 0 / 15%) 54%, rgb(0 0 0 / 5%) 72%, rgb(0 0 0 / 0%) 94%);
  height: 99px;
  width: 100%;
  font-family: "YouTube Noto", Roboto, Arial, Helvetica, sans-serif;
  color: hsl(0deg 0% 93.33%);
  text-shadow: 0 0 2px rgba(0, 0, 0, .5);
  font-size: 18px;
  padding: 25px 20px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  box-sizing: border-box;
}

lite-youtube:hover::before {
  color: white;
}

/* responsive iframe with a 16:9 aspect ratio
    thanks https://css-tricks.com/responsive-iframes/
*/
lite-youtube::after {
  content: "";
  display: block;
  padding-bottom: calc(100% / (16 / 9));
}

lite-youtube > iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border: 0;
}

/* play button */
lite-youtube > .lty-playbtn {
  display: block;
  /* Make the button element cover the whole area for a large hover/click target… */
  width: 100%;
  height: 100%;
  /* …but visually it's still the same size */
  background: no-repeat center/68px 48px;
  /* YT's actual play button svg */
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 68 48"><path d="M66.52 7.74c-.78-2.93-2.49-5.41-5.42-6.19C55.79.13 34 0 34 0S12.21.13 6.9 1.55c-2.93.78-4.63 3.26-5.42 6.19C.06 13.05 0 24 0 24s.06 10.95 1.48 16.26c.78 2.93 2.49 5.41 5.42 6.19C12.21 47.87 34 48 34 48s21.79-.13 27.1-1.55c2.93-.78 4.64-3.26 5.42-6.19C67.94 34.95 68 24 68 24s-.06-10.95-1.48-16.26z" fill="red"/><path d="M45 24 27 14v20" fill="white"/></svg>');
  position: absolute;
  cursor: pointer;
  z-index: 1;
  filter: grayscale(100%);
  transition: filter .1s cubic-bezier(0, 0, 0.2, 1);
  border: 0;
}

lite-youtube:hover > .lty-playbtn,
lite-youtube .lty-playbtn:focus {
  filter: none;
}

/* Post-click styles */
lite-youtube.lyt-activated {
  cursor: unset;
}

lite-youtube.lyt-activated::before,
lite-youtube.lyt-activated > .lty-playbtn {
  opacity: 0;
  pointer-events: none;
}

.lyt-visually-hidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}